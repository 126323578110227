import { Theme } from '@mui/material';

const styles = {
  drawer: {
    overscrollBehavior: 'contain',
    zIndex: '10000',
    maxWidth: '100vw',
    maxHeight: '100vh',
    scroll: 'hidden',
    overflow: 'hidden',
    '& .MuiDrawer-paper': {
      backgroundColor: ({ palette }: Theme) => palette.primary.light,
      padding: ({ spacing }: Theme) => spacing('1rem', '2rem'),
      maxWidht: '100vw',
      maxHeight: '100vh'
    },
    '& .MuiDrawer-paperAnchorLeft': {
      right: 0,
      minWidth: '100%',
      minHeight: '100%'
    }
  },
  linksContainer: {
    width: '100%'
  },
  linksText: {
    whiteSpace: 'nowrap',

    '& .MuiTypography-root': {
      fontSize: { xs: '1.35rem', sm: '2rem', md: '2.5rem', lg: '3rem' },
      textAlign: { xs: 'center', sm: 'left' },
      fontWeight: 'bold',
      paddingLeft: { xs: 0, sm: '12vw' },
      textTransform: 'capitalize',
      alignSelf: 'stretch',
      color: ({ palette }: Theme) => palette.common.white
    },
    langToogle: {
      color: ({ palette }: Theme) => palette.common.white,
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      textTransform: 'capitalize',
      fontSize: '1.5rem',
      paddingRight: { xs: '0rem', sm: '2rem' },
      alignSelf: 'stretch'
    }
  }
};

export default styles;
