import { useContext } from 'react';
import Link from 'next/link';
import { ThemeContext } from '@/pages/_app';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Tooltip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { SmatLogo } from '../Logos/Logos';
import TwitterIcon from '../icons/Twitter.icon';
import MobileAppLinks from './MobileAppLinks';
import styles from './styles';

const FooterHomePage = () => {
  //@ts-expect-error wontfix
  const [isDarkMode] = useContext(ThemeContext);

  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        bgcolor: ({ palette }) =>
          palette.mode === 'dark' ? '#121212' : palette.common.white,
        borderTop: '1px solid',
        borderColor: 'primary.main',
        mt: '2rem'
      }}
    >
      <Container sx={styles.mainDivV2}>
        <Container maxWidth={'xl'} sx={styles.gridMainContainers}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
            <SmatLogo
              black={!isDarkMode}
              sx={{
                height: '48px',
                width: '160px',
                cursor: 'pointer',
                display: { xs: 'initial', lg: 'initial' },
                marginRight: '2rem'
              }}
            />

            <Typography sx={styles.titleV2}>
              {'The Alternative Investment Network'}
            </Typography>
          </Box>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              sm={7}
              justifyContent={'space-between'}
            >
              {getFooterLinks().map(({ title, content }, index) => (
                <Grid
                  item
                  key={title + index}
                  sx={{ padding: { xs: '1rem', md: 'unset' } }}
                >
                  <Typography align="left" sx={styles.linksTitleV2}>
                    {title}
                  </Typography>
                  <List dense>
                    {content?.map(({ title, url /* absolute */ }, i) => (
                      <Link href={url} passHref key={title + i}>
                        <ListItem disableGutters>
                          <ListItemText sx={styles.linksV2} primary={title} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} sm={5} sx={styles.logosGrid}>
              <SocialNetworks />
              <MobileAppLinks />
            </Grid>
          </Grid>
          <Box>
            <Typography sx={styles.rights}>
              {`Smat © ${year}. All rights reserved. By using our website you agree to the use of cookies in accordance with our cookies policy.`}
            </Typography>
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

function SocialNetworks() {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box sx={styles.iconsContainer}>
        {SOCIAL_NETWORKS.map(({ name, icon, url, ariaLabel }, index) => (
          <a
            key={name + index}
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={url}
            style={{ margin: '0px 2.5px' }}
            aria-label={ariaLabel}
          >
            <Tooltip title={url ? '' : 'Coming soon'} placement={'top'} arrow>
              <span>
                <IconButton
                  disabled={!url}
                  sx={styles.roundedButtonsV2}
                  size="large"
                  aria-label={ariaLabel}
                >
                  {icon}
                </IconButton>
              </span>
            </Tooltip>
          </a>
        ))}
      </Box>
    </Box>
  );
}

const SOCIAL_NETWORKS = [
  {
    name: 'Twitter',
    icon: <TwitterIcon />,
    url: 'https://twitter.com/smat_io',
    ariaLabel: 'Open Twitter'
  },
  {
    name: 'LinkedIn',
    icon: <LinkedInIcon />,
    url: 'https://www.linkedin.com/company/smat-s-a/',
    ariaLabel: 'Open LinkedIn'
  },
  {
    name: 'Instagram',
    icon: <InstagramIcon />,
    url: 'https://www.instagram.com/smat.io/',
    ariaLabel: 'Open Instagram'
  }
  // {
  //   name: 'Youtube',
  //   icon: <YouTubeIcon />,
  //   url: 'https://www.youtube.com/channel/UCpXll_K2Bo30JfRKEpw4VCQ/featured',
  //   ariaLabel: 'Open Youtube',
  // },
];

const getFooterLinks = () => [
  {
    title: 'Smat',
    content: [
      {
        title: 'What we do',
        url: 'https://smat.io/#about',
        hashlink: false
      },
      {
        title: 'Contact us',
        url: 'https://token.smat.io/contactus',
        hashlink: false
      }
    ]
  },
  {
    title: 'Our services',
    content: [
      { title: 'Invest', url: 'https://token.smat.io/invest', hashlink: false },
      {
        title: 'Distribute',
        url: 'https://www.smat.io/distribution',
        hashlink: false
      },
      {
        title: 'Smat app',
        url: process?.env.NEXT_PUBLIC_SMAT_APP_URL || 'https://app.smat.io',
        hashlink: false,
        absolute: true
      }
    ]
  },
  {
    title: 'Legal info',
    content: [
      {
        title: 'Privacy policy',
        url: 'https://smat.io/technology',
        hashlink: false
      },
      {
        title: 'Terms & conditions',
        url: 'https://smat.io/terms-and-conditions',
        hashlink: false
      }
    ]
  }
];

export default FooterHomePage;
