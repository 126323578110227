import React from 'react';
import Image from 'next/legacy/image';
import { getAssets } from '@/services/minio.service';
import { Box, SxProps, Tooltip } from '@mui/material';

function MobileAppLinks({ sx = {} }: { sx?: SxProps }) {
  return (
    <Box
      sx={{
        gap: '1rem',
        justifyContent: { xs: 'center', md: 'flex-end' },
        display: 'flex',
        width: '100%',
        ...sx
      }}
    >
      <Tooltip placement="top" arrow title={'Download on Apple store'}>
        <a
          href={process.env.NEXT_PUBLIC_IOS_APP_STORE_URL || '/'}
          rel={'noopener noreferrer nofollow'}
          target={'_blank'}
        >
          <span>
            <Image
              src={getAssets('app_store.webp')}
              alt={'APP Store'}
              layout="fixed"
              height="40"
              width="120"
            />
          </span>
        </a>
      </Tooltip>
      <Tooltip placement="top" arrow title={'Download on Google store'}>
        <a
          href={process.env.NEXT_PUBLIC_ANDROID_APP_STORE_URL || '/'}
          rel={'noopener noreferrer nofollow'}
          target={'_blank'}
        >
          <span>
            <Image
              src={getAssets('google_play.webp')}
              alt={'Google Play'}
              layout="fixed"
              height="40"
              width="120"
            />
          </span>
        </a>
      </Tooltip>
    </Box>
  );
}

export default MobileAppLinks;
