import { CSS_TEXT, CSS_TITLE } from '@/themes/customizations';
import { Theme } from '@mui/material';

const styles = {
  mainDiv: {
    bgcolor: ({ palette }: Theme) => palette.secondary.main,
    minWidth: '100vw',
    minHeight: '23rem',
    paddingTop: '3rem',
    paddingBottom: { xs: '3rem', sm: '1rem', lg: '3rem' }
  },
  mainDivV2: {
    paddingTop: '3rem',
    paddingBottom: { xs: '6rem', sm: '1rem', lg: '3rem' },
    minHeight: '23rem'
  },
  gridMainContainers: {
    display: 'grid',
    gap: { xs: '2rem', sm: '1rem' },
    gridTemplateRows: { xs: '0.6fr 2fr', sm: '1fr 2fr' },
    gridTemplateColumns: 'auto'
  },
  title: {
    color: ({ palette }: Theme) => palette.common.white,
    fontWeight: 500,
    fontFamily: 'Poppins',
    paddingTop: { xs: 1, lg: 0 },
    fontSize: { xs: '0.7rem', lg: 'initial' }
  },
  titleV2: {
    ...CSS_TITLE,
    paddingTop: { xs: 1, lg: 0 },
    fontSize: { xs: '0.7rem', lg: 'initial' }
  },
  linksTitle: {
    fontSize: { xs: '1rem', sm: '1.429rem' },
    fontFamily: 'Poppins',
    fontWeight: 700,
    cursor: 'context-menu',
    color: ({ palette }: Theme) => palette.common.white
  },
  linksTitleV2: {
    fontSize: { xs: '1rem', sm: '1.429rem' },
    cursor: 'context-menu',
    ...CSS_TITLE
  },
  links: {
    '& > *': {
      fontSize: { xs: '0.75', sm: '1rem' },
      fontFamily: 'Poppins',
      fontWeight: 500,
      cursor: 'pointer',
      textTransform: 'capitalize',
      color: ({ palette }: Theme) => palette.common.white,
      ':hover': {
        color: ({ palette }: Theme) => palette.primary.main
      }
    }
  },
  linksV2: {
    '& > *': {
      ...CSS_TEXT,
      cursor: 'pointer',
      fontWeight: 400,
      textTransform: 'capitalize',
      fontSize: { xs: '0.75', sm: '1rem' },
      ':hover': {
        color: ({ palette }: Theme) => palette.primary.main
      }
    }
  },
  rights: {
    fontSize: '12px',

    fontWeight: 300,
    fontFamily: 'Poppins'
  },
  roundedButtons: {
    bgcolor: ({ palette }: Theme) => palette.common.white,
    color: ({ palette }: Theme) => palette.secondary.main,
    '& .MuiSvgIcon-root': {
      fontSize: { xs: '1.5rem', sm: '2rem' },
      ':hover': {
        bgcolor: ({ palette }: Theme) => palette.primary.main
      }
    }
  },
  roundedButtonsV2: {
    bgcolor: 'transparent',
    color: 'tertiary.main',
    transition: ({ transitions }: Theme) =>
      transitions.create('all', {
        duration: transitions.duration.shortest,
        easing: transitions.easing.easeIn
      }),
    ':hover': {
      color: 'primary.main'
    },
    '& .MuiSvgIcon-root': {
      fontSize: { xs: '1.714rem', sm: '2.286rem' }
    }
  },
  iconsContainer: {
    textAlign: { xs: 'center', sm: 'right' },
    margin: { xs: 1, sm: 0 },
    gap: '.5rem',
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'flex-end' }
  },
  logosGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: { xs: '1.5rem', sm: 'unset' }
  }
};

export default styles;
