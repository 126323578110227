import FooterHomePage from '@/components/FooterHomePage';
import NavBarHomePage from '@/components/NavBarHomePage';
import { Box } from '@mui/material';

const HomeLayout = ({ children, shallow = false }) => {
  return (
    <Box
      sx={{
        overflowX: 'hidden',
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontFamily: 'Montserrat',
        '&: div': {
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#DBDBDB'
        }
      }}
      className="homeLayoutContainer"
    >
      <NavBarHomePage shallow={shallow} />
      <main>{children}</main>
      <FooterHomePage />
    </Box>
  );
};

export default HomeLayout;
