import { SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Box, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/system';
import { useKeyPress } from '../../utils/hooks';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { NavbarContent, RegisterBtn } from '../NavBarHomePage';
import styles from './styles';

function DrawerMenu(props) {
  const { open, setOpen, onToggle } = props;
  const escapeKey = useKeyPress('Escape');
  const { t } = useTranslation();

  const { breakpoints } = useTheme();
  const matchesXS = useMediaQuery(breakpoints.down('sm'));

  const handleListItemClick = (
    e: SyntheticEvent<HTMLDivElement, MouseEvent>
  ): void => {
    onToggle(e);
  };

  useEffect(() => {
    if (open) {
      if (escapeKey) setOpen(false);
    }
  }, [escapeKey]);

  return (
    <Drawer
      sx={{
        ...styles.drawer,

        '& .MuiDrawer-paper': {
          backgroundColor: 'unset',
          backgroundImage:
            'linear-gradient(150deg, #1d2838 35%, rgba(21, 29, 41, 0.75))',
          padding: ({ spacing }) => spacing('1rem', '2rem'),
          maxWidht: '100vw',
          maxHeight: '100vh'
        }
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <div style={{ cursor: 'pointer' }} onClick={onToggle}>
        <NavbarContent {...props} drawer />
      </div>
      <Box
        pt={matchesXS ? 0 : 5}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={'100%'}
        flexGrow={1}
      >
        <Box
          display={'flex'}
          height={'100%'}
          flexGrow={1}
          width={'100%'}
          flexDirection="column"
        >
          <List sx={styles.linksContainer}>
            {getLinks(t).map(({ text, url }, index) => (
              <Link
                key={text + index}
                smooth
                passHref
                href={`${url}`}
                {...props}
                legacyBehavior
              >
                <ListItem
                  button
                  key={text + index}
                  onClick={(e) => handleListItemClick(e)}
                  sx={styles.linksText}
                >
                  <ListItemText primary={text} />
                </ListItem>
              </Link>
            ))}
          </List>
          {matchesXS && <RegisterBtn />}
        </Box>
        <Box width={'100%'} display={'flex'} justifyContent="flex-end">
          {open && <LanguageSelect drawer white showIcon={false} />}
        </Box>
      </Box>
    </Drawer>
  );
}

export const getLinks = (t) => [
  { text: t('nav:home'), url: 'https://smat.io' },
  { text: t('nav:about'), url: 'https://smat.io/about-us' },
  // { text: t('nav:newsroom'), url: 'https://smat.io/blog' },
  { text: t('nav:contact'), url: '/contactus' }
  // { text: t('nav:invest'), url: 'https://app.smat.io/login/email' },
];

export default DrawerMenu;
