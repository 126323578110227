import { useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FEATURES } from '@/common/config/features';
import { ThemeContext } from '@/pages/_app';
import { getAssets } from '@/services/minio.service';
import { MAIN_BUTTON, UNDERLINED_LINK } from '@/themes/customizations';
import { useOffsetScroll } from '@/utils/hooks';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { LinearProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
// import { trackEvent } from '../Analytics/Analytics';
import DrawerMenu, { getLinks } from '../Drawer';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import styles from './styles';

// const SMAT_REGISTER_TYPEFORM = process.env?.REACT_APP_REGISTER_LINK || 'https://register.smat.io/';
const SMAT_APP_LINK =
  process?.env?.NEXT_PUBLIC_SMAT_APP_URL || 'https://app.smat.io';

const NavBarHomePage = ({ shallow }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [offset] = useOffsetScroll();
  const { pathname } = useRouter();

  const handleToggleDrawer = (e) => {
    e.stopPropagation();
    setOpenMenu((prevState) => !prevState);
  };

  const isNewsroom = pathname?.includes('/newsroom/');
  const isNewsroomRoot = pathname === '/newsroom';
  const isTokenBuy = pathname?.includes('/privatesale/buytoken');
  return (
    <>
      <Box
        component={'nav'}
        sx={{
          ...styles.navBar,
          ...(!isTokenBuy && styles.fixed),
          ...(offset > 80 && styles.navBarFixed)
        }}
      >
        <Container maxWidth="xl">
          <NavbarContent
            shallow={shallow}
            onToggle={handleToggleDrawer}
            open={openMenu}
            pathname={pathname}
          />
        </Container>
        {isNewsroom && offset > 80 && (
          <div id={'newsroomProgressBar'}>
            <LinearProgress
              variant="determinate"
              value={getProgressValue(offset)}
              sx={styles.progressBarFixed}
            />
          </div>
        )}
      </Box>
      <DrawerMenu
        open={openMenu}
        setOpen={setOpenMenu}
        onToggle={handleToggleDrawer}
        isNewsroom={isNewsroomRoot}
      />
    </>
  );
};

export const NavbarContent = ({
  open,
  onToggle,
  drawer = false,
  shallow,
  pathname
}) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('lg'));
  const showV2Logo = FEATURES.V2Brand;
  // @ts-expect-error wontfix
  const [isDarkMode] = useContext(ThemeContext);
  const isHomePage = pathname === '/' || pathname === '/contactus';

  return (
    <Box
      sx={{
        ...styles.navContainer,
        ...(!drawer && isMobile && styles.navBlurBg)
      }}
    >
      <Box sx={styles.logoContainer}>
        {drawer ? (
          <Image
            src={getAssets('v2/v2-logo.webp')}
            alt="logo"
            height={showV2Logo ? (isMobile ? 30 : 48) : 24}
            width={showV2Logo ? (isMobile ? 100 : 160) : 105}
            priority
          />
        ) : (
          <Box display={'flex'} alignItems="center" sx={{}}>
            <div style={{ display: 'inline-flex' }}>
              <MuiLink
                href="https://smat.io"
                sx={styles.logo}
                style={{ flexGrow: 1 }}
              >
                <Image
                  priority
                  src={
                    isHomePage
                      ? getAssets('v2/v2-logo-tp.webp')
                      : isDarkMode
                        ? getAssets('v2/v2-logo.webp')
                        : getAssets('v2/v2-logo-tp.webp')
                  }
                  height={showV2Logo ? (isMobile ? 30 : 48) : 48}
                  width={showV2Logo ? (isMobile ? 100 : 160) : 48}
                  alt="Logo Smat"
                />
                {!isMobile && !showV2Logo && (
                  <Typography component={'p'} sx={styles.logoTitle}>
                    Smat
                  </Typography>
                )}
              </MuiLink>
            </div>
            {/* {isTokenView && (
              <Box
                flex={1}
                display={'inline-flex'}
                alignItems={'center'}
                justifyContent={isMobile ? 'center' : 'flex-end'}
              >
                <SmatTokenFAB />
              </Box>
            )} */}
          </Box>
        )}
      </Box>
      <Box
        display={'flex'}
        sx={{
          gap: '1rem',
          flex: 1,
          ...(isTablet && { justifyContent: 'flex-end' })
        }}
        alignItems="center"
      >
        {!isTablet && (
          <Box
            id={'navlinks'}
            component={'ul'}
            sx={{
              display: 'flex',
              flex: 1,
              gap: '1rem',
              justifyContent: 'space-evenly',
              '& a': {
                ...UNDERLINED_LINK,
                fontWeight: 500,
                fontFamily: 'Sora, Sans-serif',
                fontSize: '1rem'
              }
            }}
          >
            {getLinks(t).map(({ text, url }) => (
              <Link key={text} href={url} passHref legacyBehavior>
                <Typography component={'a'}>{text}</Typography>
              </Link>
            ))}
          </Box>
        )}
        {!drawer && <LanguageSelect shallow={shallow} />}
        {!isTablet && <RegisterBtn />}
        {isTablet && (
          <IconButton
            sx={{
              ...styles.hamburgerIcon,
              ...(drawer && styles.drawerHamburguer)
            }}
            onClick={onToggle}
            aria-label={`${open ? 'close' : 'open'} menu`}
            size="large"
          >
            {open ? (
              <MenuOpenIcon fontSize="large" />
            ) : (
              <MenuIcon fontSize="large" />
            )}
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export const RegisterBtn = () => {
  const { t } = useTranslation();

  return (
    <MuiLink
      href={SMAT_APP_LINK}
      target={'_blank'}
      rel={'noopener noreferrer nofollow'}
      style={{ alignSelf: 'center' }}
    >
      <Button
        onClick={(e) => {
          e.stopPropagation();
          // trackEvent({ category: 'App', action: 'Click launch app' });
        }}
        endIcon={<ArrowRightAltIcon />}
        sx={{ ...MAIN_BUTTON, textTransform: 'none' }}
      >
        {t('nav:launch')}
      </Button>
    </MuiLink>
  );
};

const getProgressValue = (offset) => {
  return Number(
    ((offset + window?.innerHeight) * 100) / document?.body?.clientHeight
  );
};

export default NavBarHomePage;
