import { Theme } from '@mui/material';

const styles = {
  navBar: {
    padding: { xs: 0, md: '50px 40px' },
    background: 'transparent',
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    top: 0,
    zIndex: 999,
    transition: 'all ease-out 0.2s',
    maxHeight: '70px'
  },
  fixed: {
    position: 'fixed'
  },
  navBarFixed: {
    background: ({ palette }: Theme) =>
      palette.mode === 'light' ? palette.common.white : palette.common.bgCard,
    position: 'fixed',
    height: '70px',
    borderBottom: ({ palette }: Theme) => `1px solid ${palette.primary.main}`
  },
  progressBarFixed: {
    '& .MuiLinearProgress-bar1Determinate': {
      position: 'fixed !important',
      top: '70px',
      bottom: 'unsed',
      height: {
        xs: '2px',
        sm: '4px'
      }
    }
  },
  mainContainer: {
    display: 'flex',
    width: 'auto'
  },
  logoContainer: {
    flexShrink: 0,
    alignSelf: 'center'
  },
  logo: {
    justifySelf: 'start',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    width: 'fit-content',
    transition: ({ transitions }: Theme) =>
      transitions.create(['transform'], {
        duration: 400,
        easing: transitions.easing.easeInOut
      }),
    '& > img': {
      width: 48
    },
    '&:hover': {
      transform: 'scale(0.94)'
    }
  },
  logoTitle: {
    color: ({ palette }: Theme) => palette.common.black,
    marginLeft: '1rem',
    fontWeight: 700,
    fontSize: '1.8rem'
  },
  hamburgerIcon: {
    placeSelf: 'center',
    color: ({ palette }: Theme) => palette.common.black
  },
  navContainer: {
    display: 'flex',
    gap: '.5rem',
    width: 'auto'
  },
  navBlurBg: {
    '::before': {
      content: '""',
      width: '100%',
      height: '100%',
      boxSizing: 'borderBox',
      position: 'absolute',
      inset: '0 0 0 0',
      pointerEvents: 'none',
      backgroundColor: ({ palette }: Theme) => palette.common.white,
      opacity: 0.5,
      zIndex: 0
    }
  },
  drawerHamburguer: {
    color: ({ palette }: Theme) => palette.common.white
  },
  v2RegisterBtn: {
    backgroundColor: ({ palette }: Theme) => palette.tertiary.main,
    color: ({ palette }: Theme) => palette.common.white,
    minWidth: 'clamp(3rem, 8rem + 5vw, 18rem)',
    justifyContent: 'space-between',
    padding: ({ spacing }: Theme) => ({
      xs: '0.5rem 1rem',
      sm: spacing(1.5, 4)
    }),
    margin: ({ spacing }: Theme) => spacing(0, 4),
    '& > *': {
      fontFamily: 'Sora, sans-serif',
      fontWeight: 600,
      fontSize: '1.2rem'
    },
    transition: ({ transitions }: Theme) =>
      transitions.create('all', {
        duration: transitions.duration.complex,
        easing: transitions.easing.easeIn
      }),
    ':hover': {
      backgroundColor: ({ palette }: Theme) => palette.purple.main,
      color: ({ palette }: Theme) => palette.common.white,
      transform: 'scale(0.94)'
    }
  },
  registerBtn: {
    backgroundColor: ({ palette }: Theme) => palette.common.white,
    color: ({ palette }: Theme) => palette.common.lightGrey,
    minWidth: 'clamp(3rem, 8rem + 5vw, 18rem)',
    justifyContent: 'space-between',
    padding: ({ spacing }: Theme) => ({
      xs: '0.5rem 1rem',
      sm: spacing(1.5, 4)
    }),
    margin: ({ spacing }: Theme) => spacing(0, 4),
    '& > *': {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '1.2rem'
    },
    ':hover': {
      backgroundColor: ({ palette }: Theme) => palette.secondary.main,
      color: ({ palette }: Theme) => palette.common.white
    }
  },
  langToogleWt: {
    color: ({ palette }: Theme) => palette.common.black + ' !important'
  },
  langToogle: {
    color: ({ palette }: Theme) => palette.secondary.main,
    fontWeight: 700,
    fontSize: '1.25rem',
    width: '3.8rem',
    height: '3.8rem',
    ':hover': {
      textDecoration: 'underline'
    }
  }
};

export default styles;
